<template>
  <div>
    <table-wrapper
      :table-header="headers"
      :toggle-buttons="toggleButtons"
      :minimal="minimal"
      :custom-search="doCustomSearch"
      :change-page-size="changePageSize"
      :update-search-string="updateSearchString"
      :fetch-data="fetchData"
      :manage-filter="manageFilter"
      :pager="pager"
      :rows="rows"
      :list-length="userAnalysis.length"
      :standalone="standalone"
      :is-loading="isLoading"
    >
      <template #body>
        <user-analysis-history-row
          v-for="analysis in userAnalysis"
          :key="analysis.requestName"
          :user-analysis="analysis"
          statuses="analysis"
          hide-detail
          :buy-action="buyProduct"
        />
      </template>
      <template #noResults>
        <div>
          <loader-info v-if="isLoading" />
          <div v-else>
            <p class="my-3 text-center">
              <em>
                <strong>
                  Non hai ancora richiesto alcun prodotto/servizio oppure la ricerca non ha prodotto risultati
                </strong>
              </em>
            </p>
            <b-row class="mb-3">
              <b-col sm="6" md="3" class="mx-auto">
                <div class="mb-3 text-center">
                  <b-button
                    size="sm"
                    variant="info"
                    block
                    @click="routeTo('product-list')"
                  >
                    RICHIEDI UN SERVIZIO
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
      <template #actions>
        <b-button
          variant="warning"
          size="sm"
          @click="downloadList"
        >
          Scarica elenco
        </b-button>
      </template>
      <template #filters>
        <p>
          <strong>Filtri aggiuntivi</strong>
        </p>
        <b-form-row>
          <b-col sm="6" lg="4" xl="3">
            <field-calendar
              title="Data inizio"
              v-model="startDate"
              has-erase
              :state="true"
            />
          </b-col>
          <b-col sm="6" lg="4" xl="3">
            <field-calendar
              title="Data fine"
              v-model="endDate"
              has-erase
              :state="true"
            />
          </b-col>
        </b-form-row>
      </template>
    </table-wrapper>
    <quick-pay-modal
      :id="quickPayModalId"
      :request-bought="currentItem"
      :on-close="onModalClose"
    />
  </div>
</template>

<script>
import {
  extractErrorMessage, isNotEmpty, isNotExist, isPresent,
} from '@/utils/validators';

const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
const UserAnalysisHistoryRow = () => import('@/components/userAnalysis/UserAnalysisHistoryRow.vue');
const QuickPayModal = () => import('@/components/quickPay/QuickPayModal.vue');
const TableWrapper = () => import('@/components/tableWrapper/TableWrapper.vue');
const FieldCalendar = () => import('@/components/fields/FieldCalendar.vue');

export default {
  name: 'UserAnalysisHistoryTable',
  components: {
    FieldCalendar,
    QuickPayModal,
    TableWrapper,
    UserAnalysisHistoryRow,
    LoaderInfo,
  },
  props: {
    completeData: Boolean,
    dataSize: Number,
    minimal: Boolean,
    standalone: Boolean,
    updateKey: String,
  },
  data() {
    return {
      searchBase: {
        search: '',
        page: 0,
        size: this.dataSize || 10,
        sort: [{ field: 'lastModified', direction: 'DESC' }],
        filters: [],
      },
      entityType: null,
      entityId: null,
      submitted: false,
      note: null,
      currentItem: null,
      searchKeywords: '',
      customFilters: false,
      filters: {
        statusInProgress: false,
        statusWaitPayment: false,
        statusCompleted: false,
      },
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['analysis/isLoading'];
    },
    userAnalysis() {
      return this.$store.getters['analysis/userAnalysisList'];
    },
    pager() {
      return this.$store.getters['analysis/userAnalysisPageable'];
    },
    rows() {
      return this.pager.totalElements;
    },
    quickPayModalId() {
      return 'analysisQuickPayModal';
    },
    toggleButtons() {
      return [
        {
          label: 'In lavorazione',
          action: 'statusInProgress',
          variant: 'warning',
          key: 'IN_PROGRESS',
          value: false,
        },
        {
          label: 'Attesa pagamento',
          action: 'statusWaitPayment',
          variant: 'warning',
          key: 'WAIT_PAYMENT',
          value: false,
        },
        {
          label: 'Completato',
          action: 'statusCompleted',
          variant: 'success',
          key: 'COMPLETED',
          value: false,
        },
      ];
    },
    headers() {
      return [
        {
          classes: 'table-cell--compact',
          content: 'Stato / Codice',
        },
        {
          classes: 'white-space-nowrap',
          content: 'Date',
        },
        {
          classes: 'white-space-nowrap',
          content: 'Soggetto',
        },
        {
          classes: 'w-100',
          content: 'Tipologia di richiesta',
        },
        {
          classes: 'table-cell--compact',
          content: '',
        },
      ];
    },
  },
  watch: {
    completeData(val, oldVal) {
      if (val !== oldVal && val) this.fetchData(true);
    },
    updateKey(val, oldVal) {
      // console.log('check id', val, oldVal);
      if (val !== oldVal && val) this.fetchData(true);
    },
  },
  mounted() {
    // console.log('mounting table', this.completeData);
    this.fetchData(true);
  },
  methods: {
    fetchData(reload, nextPage) {
      // console.log('list fectad', this.searchBase, nextPage);
      const currentProfile = this.$store.getters['subject/currentCompany'];
      if (isNotEmpty(currentProfile)) {
        this.entityId = currentProfile.companyId;
        this.entityType = 'company';
      } else {
        this.entityType = 'person';
        this.entityId = this.$store.getters['subject/currentPerson']?.personId;
      }

      if (isNotExist(this.entityId)) return;

      const search = this.buildSearch(nextPage);
      this.$store.dispatch('analysis/searchUserAnalysis', {
        entityId: this.entityId,
        entityType: this.entityType,
        search,
        reset: reload,
      })
        .then(() => {
          this.submitted = false;
          this.searchBase.page = search.page;
        },
        (error) => {
          this.message = extractErrorMessage(error);
          this.submitted = false;
        });
    },
    buildSearch(nextPage) {
      const filters = [];
      const statusFilter = [];
      if (isNotEmpty(this.toggleButtons)) {
        this.toggleButtons.map((filter) => {
          // console.log('checking filters', filter);
          if (filter.value) {
            return statusFilter.push(filter.key);
          }
          return false;
        });
      }

      if (statusFilter.length > 0) {
        filters.push({
          field: 'status',
          operation: 'IN',
          value: statusFilter,
        });
      }

      if (isPresent(this.startDate) && isPresent(this.endDate)) {
        filters.push({
          field: 'createdAt',
          operation: 'BETWEEN',
          value: this.startDate,
          secondValue: this.endDate,
          date: true,
        });
      } else {
        if (isPresent(this.startDate)) {
          filters.push({
            field: 'createdAt',
            operation: 'GTE',
            value: this.startDate,
            date: true,
          });
        }
        if (isPresent(this.endDate)) {
          filters.push({
            field: 'createdAt',
            operation: 'LTE',
            value: this.endDate,
            date: true,
          });
        }
      }

      const search = { ...this.searchBase, filters };

      // console.log('fetch', nextPage);
      if (isNotEmpty(nextPage)) {
        const paging = this.$store.getters['analysis/userAnalysisPageable'];
        if (nextPage > 0 && nextPage <= paging.totalPages) {
          search.page = nextPage - 1;
        }
      }
      return search;
    },
    routeTo(path) {
      this.$router.push(path);
    },
    buyProduct(item) {
      this.currentItem = this.userAnalysis.find((row) => row.requestName === item);
      this.$bvModal.show(this.quickPayModalId);
    },
    onModalClose() {
      this.fetchData(true);
    },
    doCustomSearch() {
      this.customFilters = true;
      this.fetchData(true);
    },
    changePageSize(size) {
      this.searchBase.size = size;
      this.customFilters = true;
      this.fetchData(true);
    },
    updateSearchString(val) {
      this.searchBase.search = val;
    },
    manageFilter(status, value) {
      const acc = this.toggleButtons.filter((filter) => filter.action === value);
      acc[0].value = status;
      this.fetchData(true);
    },
    downloadList() {
      // console.log('getting requests');
      if (isNotExist(this.entityId)) return;

      const search = this.buildSearch();
      this.$store.dispatch('analysis/exportUserAnalysis', {
        entityId: this.entityId,
        entityType: this.entityType,
        search,
      });
    },
  },
};
</script>

<style scoped>

</style>
